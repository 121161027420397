var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('HeaderFormItem',{attrs:{"instruction":`${
            _vm.attribute.instruction ? _vm.attribute.instruction : ''
        } ${_vm.requiredasterisk}`,"description":`${
            _vm.attribute.description ? _vm.attribute.description : ''
        } `,"required":`${_vm.requiredmessage} `}}),_c('div',{staticClass:"flex items-center w-full"},[_c('i',{class:_vm.attribute.response_private == 1
                    ? 'fas fa-lock px-1 pt-2'
                    : ' '}),_c('b-field',{staticClass:"w-full",attrs:{"label":`${_vm.attribute.label ? _vm.attribute.label : ''} ${_vm.asterisk}`,"message":_vm.attribute.help ? _vm.attribute.help : ''}},[_c('b-datepicker',{attrs:{"placeholder":_vm.attribute.config.placeholder &&
                    _vm.attribute.config.placeholder.length > 0
                        ? _vm.attribute.config.placeholder[this.$i18n.locale]
                        : '',"icon":"calendar-today","icon-right":_vm.selected ? 'close-circle' : '',"icon-right-clickable":"","locale":_vm.locale,"first-day-of-week":_vm.firstDayOfWeek,"trap-focus":""},on:{"icon-right-click":_vm.clearDate},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('b-field',{attrs:{"message":_vm.message,"type":"is-danger font-bold text-center"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }