var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center w-full"},[_c('i',{class:_vm.attribute.response_private == 1
                    ? 'fas fa-lock px-1 pt-2'
                    : ' '}),_c('b-field',{staticClass:"w-full",attrs:{"message":_vm.message,"type":"is-danger font-bold text-center"}},[_c('b-field',{attrs:{"label":`${
                    _vm.attribute.label ? _vm.attribute.label + _vm.asterisk : ''
                }`},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
                            _vm.attribute.description
                                ? _vm.attribute.description
                                : ''
                        )}})]},proxy:true}])},[_c('b-checkbox',{staticClass:"gdprInput",model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}},[_vm._v(_vm._s(_vm.attribute.instruction ? _vm.attribute.instruction : _vm.attribute.label)+_vm._s(_vm.attribute.label ? '' : _vm.asterisk)+" ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }