var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('HeaderFormItem',{attrs:{"instruction":`${
            _vm.attribute.instruction ? _vm.attribute.instruction : ''
        } ${_vm.requiredasterisk}`,"description":`${
            _vm.attribute.description ? _vm.attribute.description : ''
        } `,"required":`${_vm.requiredmessage} `}}),_c('div',{staticClass:"flex items-center w-full"},[_c('div',{staticClass:"flex-column w-full"},[_c('div',{staticClass:"mb-2 flex align-items-stretch"},[_c('i',{class:_vm.attribute.response_private == 1
                            ? 'fas fa-lock'
                            : ' '}),_c('label',{staticClass:"text-gray-700 text-base font-bold mb-2"},[_vm._v(" "+_vm._s(_vm.attribute.label ? _vm.attribute.label : '')+" "+_vm._s(_vm.asterisk)+" ")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.result),expression:"result"}],staticClass:"selectInput border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500",attrs:{"type":"email","placeholder":_vm.attribute.config.placeholder
                        ? _vm.attribute.config.placeholder[this.$i18n.locale][0]
                        : ''},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.result=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.options),function(option,index){return _c('option',{key:index,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('p',{staticClass:"text-gray-700 text-xs mb-2"},[_vm._v(" "+_vm._s(_vm.attribute.help ? _vm.attribute.help : '')+" ")])])]),_c('b-field',{attrs:{"message":_vm.message,"type":"is-danger font-bold text-center"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }