var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"category"}},[_c('div',{staticClass:"flex items-center justify-center"},[_c('i',{class:_vm.attribute.response_private == 1
                    ? 'fas fa-lock pr-2 mb-6 '
                    : ' '}),_c('HeaderFormItem',{attrs:{"help":_vm.attribute.help,"instruction":`${
                _vm.attribute.instruction ? _vm.attribute.instruction : ''
            } ${_vm.requiredasterisk}`,"description":`${
                _vm.attribute.description ? _vm.attribute.description : ''
            } `,"required":`${_vm.requiredmessage} `}})],1),_c('div',{staticClass:"flex justify-around flex-wrap items-center"},_vm._l((_vm.options),function(option,index){return _c('div',{key:option.label,staticClass:"text-center cursor-pointer my-2",class:{
                    ' border-4 border-indigo-700 rounded-md':
                        _vm.result &&
                        _vm.result.value &&
                        _vm.result.value.label === option.label
                },on:{"click":function($event){$event.preventDefault();return _vm.setSelected(index)}}},[_c('button',{staticClass:"md:px-6 md:py-4 pt-6 pb-4 max-h-max border-4"},[_c('figure',{staticClass:"is-inline-block mb-2 px-6"},[(option.icon)?_c('i',{staticClass:"icon text-indigo-700 inline-flex",class:option.icon}):_c('i',{staticClass:"fa-solid fa-folder icon text-indigo-700 inline-flex"})]),_c('div',{staticClass:"content md:text-base text-sm"},[_vm._v(" "+_vm._s(option.label)+" ")])])])}),0),_c('b-field',{attrs:{"message":_vm.message,"type":"is-danger font-bold text-center"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }