var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('HeaderFormItem',{attrs:{"instruction":`${
            _vm.attribute.instruction ? _vm.attribute.instruction : ''
        } ${_vm.requiredasterisk}`,"description":`${
            _vm.attribute.description ? _vm.attribute.description : ''
        } `,"required":`${_vm.requiredmessage} `}}),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex-column w-full"},[_c('div',{staticClass:"mb-2 flex align-items-stretch"},[_c('i',{class:_vm.attribute.response_private == 1
                            ? 'fas fa-lock'
                            : ' '}),_c('label',{staticClass:"text-gray-700 text-base font-bold mb-2"},[_vm._v(" "+_vm._s(_vm.attribute.label ? _vm.attribute.label : '')+" "+_vm._s(_vm.asterisk)+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.result),expression:"result"}],staticClass:"textInput appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500",attrs:{"type":"text","placeholder":_vm.attribute.config.placeholder
                        ? _vm.attribute.config.placeholder[this.$i18n.locale]
                        : ''},domProps:{"value":(_vm.result)},on:{"input":function($event){if($event.target.composing)return;_vm.result=$event.target.value}}}),_c('p',{staticClass:"text-gray-700 text-xs mb-2"},[_vm._v(" "+_vm._s(_vm.attribute.help ? _vm.attribute.help : '')+" ")])])]),_c('b-field',{attrs:{"message":_vm.message,"type":"is-danger font-bold text-center"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }