var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('HeaderFormItem',{attrs:{"instruction":`${
            _vm.attribute.instruction ? _vm.attribute.instruction : ''
        } ${_vm.requiredasterisk}`,"description":`${
            _vm.attribute.description ? _vm.attribute.description : ''
        } `,"required":`${_vm.requiredmessage} `}}),_c('div',{staticClass:"flex items-center w-full"},[_c('i',{class:_vm.attribute.response_private == 1
                    ? 'fas fa-lock px-1 pt-2'
                    : ' '}),_c('b-field',{staticClass:"w-full",attrs:{"label":`${_vm.attribute.label ? _vm.attribute.label : ''} ${_vm.asterisk}`,"message":_vm.attribute.help ? _vm.attribute.help : ''}},[_c('b-numberinput',{attrs:{"controls-alignment":"right","controls-position":"compact"},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}})],1)],1),_c('b-field',{attrs:{"message":_vm.message,"type":"is-danger font-bold text-center"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }