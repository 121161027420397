var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex align-items-stretch justify-content-center"},[_c('i',{staticClass:"align-self-start",class:_vm.attribute.response_private == 1
                      ? 'fas fa-lock mb-6 mr-4'
                      : ' '}),_c('HeaderFormItem',{attrs:{"help":_vm.attribute.help,"instruction":`${
                  _vm.attribute.instruction ? _vm.attribute.instruction : ''
              } ${_vm.requiredasterisk}`,"description":`${
                  _vm.attribute.description ? _vm.attribute.description : ''
              } `,"required":`${_vm.requiredmessage} `}})],1),_c('b-field',{attrs:{"message":_vm.message,"type":"is-danger font-bold text-center"}},[_c('MapFindPlace',{attrs:{"result":_vm.result},on:{"onLocationSelected":_vm.setSelected,"onUserLocated":_vm.setUserLocation}})],1),_c('l-map',{ref:"myMap",staticStyle:{"height":"calc(100vh - 330px)","width":"100%","z-index":"1"},attrs:{"center":_vm.center,"zoom":_vm.zoom},on:{"click":function($event){return _vm.setPoint($event)},"update:zoom":function($event){return _vm.updateZoom($event)}}},[_c('l-tile-layer',{attrs:{"options":_vm.optionsZoom,"attribution":_vm.attribution,"url":_vm.url}}),_vm._l((_vm.cities),function(city,index){return _c('l-geo-json',{key:index,attrs:{"geojson":city,"options":_vm.options}})}),(_vm.location)?_c('l-marker',{ref:"maPosition",attrs:{"icon":_vm.icon,"lat-lng":_vm.location}},[_c('l-popup',[_c('div',[_vm._v("Ma position")])])],1):_vm._e(),(_vm.pois.length > 0)?_c('l-control',{staticClass:"leaflet-custom-control w-100 mr-0"},[_c('i',{staticClass:"fas fa-exclamation-triangle text-primary main-icon",on:{"click":function($event){$event.preventDefault();_vm.showPOIBox = !_vm.showPOIBox}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPOIBox),expression:"showPOIBox"}],staticClass:"content pr-4 pl-4 pt-3 pb-3 btn text-left mr-auto ml-auto shadow w-75"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"font-weight-bold text-dark text-uppercase d-block ml-auto mr-auto mb-3"},[_vm._v(" POI ")]),_c('i',{staticClass:"fas fa-times text-gray-500",on:{"click":function($event){$event.preventDefault();_vm.showPOIBox = false}}})]),_c('div',{staticClass:"d-flex"},_vm._l((_vm.pois),function(poi,index){return _c('div',{key:poi.id,staticClass:"custom-control custom-switch flex-wrap d-flex flex-row p-0"},[_c('div',{staticClass:"d-flex flex-wrap border p-2"},[_c('i',{staticClass:"text-primary",class:poi.icon}),_c('label',{staticClass:"custom-control-label",class:{
                                      active: poi.visible,
                                      'bg-primary': poi.visible
                                  },attrs:{"for":poi.id}}),_c('input',{staticClass:"custom-control-input d-block",attrs:{"id":poi.id,"type":"checkbox"},domProps:{"checked":poi.visible},on:{"change":function($event){poi.visible = !poi.visible}}})]),_c('div',{staticClass:"mt-1 mb-0 text-center text-dark",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(poi.name)+" ")])])}),0)])]):_vm._e(),_vm._l((_vm.pois),function(poi,index){return _c(poi.type,{key:poi.id,ref:"poi",refInFor:true,tag:"component",attrs:{"poi":poi,"poiIndex":index,"visible":poi.visible === true && _vm.zoom > 15}})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }